import React from "react";
import Storyblok, { version } from "../lib/storyblok-client";
import { relationsToResolve } from "@/constants/general";
import Components from "../components/components";
import { getSeoFromStory } from "@/lib/seo";
import { GetBlogStories, getBlogStories } from "@/lib/storyblok-gapi";
import { useStoryblok } from "../helpers/storyblok-hook";
import { isProduction } from "../helpers/environment";
import { Story } from "@/types/storyblok";

interface Props {
  story?: Story;
  latestBlogStories?: GetBlogStories["BlogItems"]["items"];
}

const Index = ({ story, latestBlogStories }: Props) => {
  story = useStoryblok(story, !isProduction);

  return (
    story &&
    React.createElement(Components(story?.content?.component), {
      key: story?.uuid,
      content: story?.content,
      published: story.published_at,
      latestBlogStories,
    })
  );
};

export default Index;

export async function getStaticProps() {
  try {
    const {
      data: { story },
    } = await Storyblok.get(`cdn/stories/home`, {
      resolve_relations: relationsToResolve,
      version,
    });

    const latestBlogStories = await getBlogStories(3);

    return {
      props: {
        story,
        seo: await getSeoFromStory({ story }),
        latestBlogStories: latestBlogStories?.BlogItems?.items,
        revalidate: 86400,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      notFound: true,
    };
  }
}
